import {LogoModel} from '../models/logo.model';
import {AfterViewInit, Component, HostListener, OnInit} from '@angular/core';
import {InfoCardModel} from '../models/info-card.model';
import {ScheduleModel} from '../models/schedule.model';


declare var $;
declare var ScrollPosStyler;

@Component({
  selector: 'civ-datathon',
  templateUrl: './datathon.component.html',
  styleUrls: ['./datathon.component.scss']
})
export class DatathonComponent implements OnInit, AfterViewInit {

  signupURL = 'https://banatit.ro/event/civicdev-datathon/';
  locationURL = 'https://www.google.ro/maps/place/Faculty+of+Arts+and+Design/' +
    '@45.7596204,21.2270897,17z/data=!3m1!4b1!4m5!3m4!1s0x474567818a2360a1:0x2ed22b1ecd88de54!8m2!3d45.7596167!4d21.2292837';
  descriptionMarkup = '<p>La civicDev strângem date împreună: de la orarul instituțiilor, la meniurie restaurantelor preferate,\n' +
    'cartarea pistelor de biciclete sau a informațiilor despre clădirile istorice, le strângem și le publicăm împreună.\n' +
    '</p>' +
    '<br>' +
    '<p>Pentru că fără date nu știm. Fără date doar credem.</p>';

  logosParteneri: LogoModel[] = [];
  logosSponsori: LogoModel[] = [];

  infoCards: InfoCardModel[] = [];

  day1Schedule: ScheduleModel;
  day2Schedule: ScheduleModel;

  ngOnInit(): void {

    const brd: LogoModel = {
      targetUrl: 'https://www.brd.ro/',
      imageUrl: 'assets/logos/BRD.png',
      order: 1,
      name: 'BRD'
    };

    const intel: LogoModel = {
      targetUrl: 'https://www.movidius.com/',
      imageUrl: 'assets/logos/intel.png',
      order: 5,
      name: 'Intel'
    };

    const civicultura: LogoModel = {
      targetUrl: 'http://civicultura.ro',
      imageUrl: 'assets/logos/civicultura.png',
      order: 1,
      name: 'CiviCultura'
    };

    const code4: LogoModel = {
      targetUrl: 'http://code4.ro',
      imageUrl: 'assets/logos/Code4Romania.png',
      order: 2,
      name: 'Code4 Romania'
    };

    const devPlant: LogoModel = {
      targetUrl: 'https://devplant.ro',
      imageUrl: 'assets/logos/devplant.svg',
      order: 5,
      name: 'DevPlant'
    };

    const hot: LogoModel = {
      targetUrl: 'https://heritageoftimisoara.ro/',
      imageUrl: 'assets/logos/hot.png',
      order: 7,
      name: 'Heritage of Timisoara'
    };

    const smartCity: LogoModel = {
      targetUrl: 'https://www.facebook.com/mysmartcity.ro/',
      imageUrl: 'assets/logos/smartcity.png',
      order: 10,
      name: 'Smart City'
    };

    const smartPol: LogoModel = {
      targetUrl: 'https://www.smartpol.org/',
      imageUrl: 'assets/logos/smartpol.png',
      order: 15,
      name: 'SMART POL'
    };

    const upt: LogoModel = {
      targetUrl: 'https://www.upt.ro/',
      imageUrl: 'assets/logos/upt.jpg',
      order: 20,
      name: 'UPT'
    };

    const uvt: LogoModel = {
      targetUrl: 'https://www.uvt.ro/',
      imageUrl: 'assets/logos/uvt.png',
      order: 25,
      name: 'UPT'
    };

    this.logosParteneri.push(civicultura, code4, devPlant, hot, smartCity, smartPol, upt, uvt);
    this.logosSponsori.push(brd, intel);

    this.infoCards.push({
      title: 'Accesibilitate',
      text: 'Care instituții publice sau private au rampe de acces pentru persoane cu probleme de mobilitate? În viitor se poate extinde Google Maps cu rute pentru scaun cu rotile.',
      icon: 'assets/imagery/accesibilitate.svg'
    });

    this.infoCards.push({
      title: 'Nutriție',
      text: 'Unde pot mânca ceva sănătos? Putem cataloga oferta restaurantelor cu informații nutritive, cum ar fi alergeni. Pe baza acestor date se pot crea aplicații cu filtre nutritive.',
      icon: 'assets/imagery/nutritie.svg'
    });

    this.infoCards.push({
      title: 'Istorie',
      text: 'La 30 de ani de la Revoluție reconstituim poveștile celor care au strigat "Libertate!" pe străzile Timișoarei. Reconstruim împreună harta evenimentelor din decembrie 1989.',
      icon: 'assets/imagery/istorie.svg'
    });

    this.infoCards.push({
      title: 'Infrastructură',
      text: 'Care sunt timpii de așteptare la semafor pentru mijloacele de transport in comun? Putem să obținem datele în mod automat sau trebuie să le măsurăm?',
      icon: 'assets/imagery/infrastructura.svg'
    });

    this.infoCards.push({
      title: 'Arhitectură',
      text: 'Cum arăta Timișoara în perioada interbelică? Există diverse ilustrații și documente, iar cu tehnologia de azi am putea recrea Timișoara de altădată în VR.',
      icon: 'assets/imagery/arhitectura.svg'
    });

    this.infoCards.push({
      title: 'Contribuie cu date la:',
      text: '<ul>' +
        '<li>Timișoara Data Portal</li>' +
        '<li>OpenStreetMap</li>' +
        '<li>Wikipedia</li>' +
        '<li>Google Maps</li>' +
        '<li>Foursquare</li>' +
        '</ul>',
      icon: 'assets/imagery/multe_altele.svg'
    });

    this.day1Schedule = {
      title: 'Sâmbătă',
      subtitle: '23 Februarie',
      location: 'Mansarda Facultății de Arte',
      entries: [
        {
          time: '09:00',
          labels: ['Înregistrare', 'Deschidere']
        },
        {
          time: '10:00',
          labels: ['Despre Open Data - Silviu Vert']
        },
        {
          time: '12:00',
          labels: ['Despre ckan - Costin Bleotu', 'Datathon']
        },
        {
          time: '13:00',
          labels: ['Prânz', 'Datathon']
        },
        {
          time: '20:00',
          labels: ['Cină', 'Datathon']
        }
      ]
    };

    this.day2Schedule = {
      title: 'Duminică',
      subtitle: '24 Februarie',
      location: 'Mansarda Facultatii de Arte',
      entries: [
        {
          time: '09:00',
          labels: ['Mic dejun', 'Datathon']
        },
        {
          time: '12:00',
          labels: ['Prânz', 'Datathon']
        },
        {
          time: '16:00',
          labels: ['Prezentări finale', 'Datathon']
        },
      ]
    };

  }

  ngAfterViewInit(): void {
    // Highlight the top nav as scrolling occurs
    $('body').scrollspy({
      target: '.navbar-fixed-top',
      offset: 51
    });

    // Closes the Responsive Menu on Menu Item Click
    $('.navbar-collapse ul li a').click(() => {
      $('.navbar-toggler:visible').click();
    });


  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    const number = document.documentElement.scrollTop;
    if (number > 51) {
      $('#mainNav').addClass('fixed-top');
    } else {
      $('#mainNav').removeClass('fixed-top');
    }
  }


}
