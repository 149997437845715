import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'civ-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {


  @Input() signupURL: string;

  constructor() { }

  ngOnInit() {
  }

  clickSignup(){
    window.open(this.signupURL, '_blank');
  }

}
