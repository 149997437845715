import {Component, Input, OnInit} from '@angular/core';
import {ScheduleModel} from '../models/schedule.model';

@Component({
  selector: 'civ-schedule-wrapper',
  templateUrl: './schedule-wrapper.component.html',
  styleUrls: ['./schedule-wrapper.component.scss']
})
export class ScheduleWrapperComponent implements OnInit {


  @Input() day1Schedule: ScheduleModel;
  @Input() day2Schedule: ScheduleModel;

  constructor() {}

  ngOnInit() {
  }

}
