import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';
import {DatathonComponent} from './datathon/datathon.component';
import {HackathonComponent} from './hackaton/hackathon.component';

const routes: Routes = [
  { path: 'datathon', component: DatathonComponent},
  { path: 'hackathon', component: HackathonComponent },
  { path: '', component: HackathonComponent },
  // { path: '**', component: DatathonComponent}
];

const routerOptions: ExtraOptions = {
  useHash: true,
  anchorScrolling: 'enabled',
  scrollPositionRestoration: 'enabled'
  // ...any other options you'd like to use
};

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule]
})
export class CivRoutingModule { }
