import {Component, Input, OnInit} from '@angular/core';
import {InfoCardModel} from '../models/info-card.model';
import {LogoModel} from '../models/logo.model';

@Component({
  selector: 'civ-info-card-container',
  templateUrl: './info-card-container.component.html',
  styleUrls: ['./info-card-container.component.scss']
})
export class InfoCardContainerComponent implements OnInit {

  @Input() logosParticipanti: LogoModel[];

  @Input() infoCards: InfoCardModel[];

  constructor() {

  }

  ngOnInit() {
  }

}
