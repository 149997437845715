import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {CarouselConfig} from 'ngx-bootstrap';

@Component({
  selector: 'civ-headline',
  templateUrl: './headline.component.html',
  styleUrls: ['./headline.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {provide: CarouselConfig, useValue: {interval: 5000, noPause: true, showIndicators: false}}
  ]
})
export class HeadlineComponent implements OnInit {


  @Input() locationURL: string;
  @Input() signupURL: string;
  @Input() locationText: string;
  @Input() dateText: string;
  @Input() hashtagText: string;
  @Input() descriptionMarkup: string;

  constructor() {
  }

  ngOnInit() {
  }

  clickLocation() {
    window.open(this.locationURL, '_blank');
  }

  clickSignup() {
    window.open(this.signupURL, '_blank');
  }


}
