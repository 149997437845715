import {Component, Input, OnInit} from '@angular/core';
import {ScheduleModel} from '../models/schedule.model';


export interface RowModel {

  left: string;
  right: string;

}

@Component({
  selector: 'civ-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.scss']
})
export class ScheduleComponent implements OnInit {

  @Input() schedule: ScheduleModel;

  tableModel: RowModel[] = [];

  constructor() { }

  ngOnInit() {
    for(let entry of this.schedule.entries){
      for(let i=0;i<entry.labels.length;i++){
        const label = entry.labels[i];
        if(i==0){
          this.tableModel.push({
            left: entry.time,
            right: label
          });
        }else{
          this.tableModel.push({
            left: null,
            right: label
          });
        }
      }
    }
  }

}
