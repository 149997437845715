import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {CivRoutingModule} from './civ-routing.module';
import {CivComponent} from './civ.component';
import {HeaderComponent} from './header/header.component';
import {FooterComponent} from './footer/footer.component';
import {ContactComponent} from './contact/contact.component';
import {LogoCardComponent} from './logo-card/logo-card.component';
import {InlineSvgDirective} from './utils/inline-svg.directive';
import {HttpClientModule} from '@angular/common/http';
import {LogoLayoutComponent} from './logo-layout/logo-layout.component';
import {ScheduleComponent} from './schedule/schedule.component';
import {ScheduleWrapperComponent} from './schedule-wrapper/schedule-wrapper.component';
import {InfoCardComponent} from './info-card/info-card.component';
import {InfoCardContainerComponent} from './info-card-container/info-card-container.component';
import {HeadlineComponent} from './headline/headline.component';
import {CarouselModule} from 'ngx-bootstrap/carousel';
import { DatathonComponent } from './datathon/datathon.component';
import { HackathonComponent } from './hackaton/hackathon.component';

@NgModule({
  declarations: [
    CivComponent,
    HeaderComponent,
    FooterComponent,
    ContactComponent,
    LogoCardComponent,
    InlineSvgDirective,
    LogoLayoutComponent,
    ScheduleComponent,
    ScheduleWrapperComponent,
    InfoCardComponent,
    InfoCardContainerComponent,
    HeadlineComponent,
    DatathonComponent,
    HackathonComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    CarouselModule.forRoot(),
    CivRoutingModule
  ],
  providers: [],
  bootstrap: [CivComponent]
})
export class CivModule {
}
