import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {LogoModel} from '../models/logo.model';

@Component({
  selector: 'civ-logo-layout',
  templateUrl: './logo-layout.component.html',
  styleUrls: ['./logo-layout.component.scss']
})
export class LogoLayoutComponent implements OnChanges {

  @Input() header: string;

  @Input() logos: LogoModel[];


  constructor() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.logos) {
      this.logos = this.logos.sort((a, b) => {
        return a.order - b.order;
      })
    } else {
      this.logos = [];
    }
  }

}
