import {Directive, ElementRef, Input, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Directive({
  selector: '[civInlineSvg]'
})
export class InlineSvgDirective implements OnInit {

  @Input() civInlineSvg: string;

  constructor(private httpClient: HttpClient, private elementRef: ElementRef) {

  }

  ngOnInit(): void {
    this.httpClient.get(this.civInlineSvg, {responseType: 'text'}).subscribe((svg) => {
      const el = <HTMLElement>this.elementRef.nativeElement;
      el.innerHTML = svg.toString();
    })
  }

}
