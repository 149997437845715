import { Component, OnInit } from '@angular/core';
import {InfoCardModel} from '../models/info-card.model';
import {ScheduleModel} from '../models/schedule.model';
import {LogoModel} from '../models/logo.model';

@Component({
  selector: 'civ-hackathon',
  templateUrl: './hackathon.component.html',
  styleUrls: ['./hackathon.component.scss']
})
export class HackathonComponent implements OnInit {

  signupURL = 'https://banatit.ro/event/civicdev-datahack01/';
  locationURL = 'https://goo.gl/maps/TtxeLo3sjdMk1mZM9';
  descriptionMarkup = '<p>La <a href="/#/datathon" class="headline-link" target="_blank">civicDev Datathon</a> am strâns date împreună. Continuăm și extindem acest proces: strângem date și le prelucrăm.<br/>' +
    'Ne imaginăm GeoJSON pentru piste de biciclete, IoT și Machine Learning pentru un drum cât mai sigur. Avem date despre autorizațiile de construcție, cu AI putem estima când se și finalizează.' +
    '</p>\n' +
    '<br>\n' +
    '<p>Pentru că fără date nu știm. Fără date doar credem.</p>';

  logosParteneri: LogoModel[] = [];
  logosSponsori: LogoModel[] = [];

  infoCards: InfoCardModel[] = [];

  day1Schedule: ScheduleModel;
  day2Schedule: ScheduleModel;

  constructor() {

    this.infoCards.push({
      title: 'Prelucrare',
      text: 'Fie că ești specialist sau vrei să înveți despre Big Data, Deep Learning, AI, Data Processing, la CivicDev îți punem la dispoziție atât date, cât și un context în care poți demonstra că DA, SE POATE.',
      icon: 'assets/imagery/infrastructura.svg'
    });

    this.infoCards.push({
      title: 'Contribuie cu date la:',
      text: '<ul>' +
        '<li>Timișoara Data Portal</li>' +
        '<li>OpenStreetMap</li>' +
        '<li>Wikipedia</li>' +
        '<li>Google Maps</li>' +
        '<li>Foursquare</li>' +
        '</ul>',
      icon: 'assets/imagery/multe_altele.svg'
    });

    this.infoCards.push({
      title: 'Standardizare',
      text: 'Aplică standardele de formatare pentru seturile de date existente. Fie că preluăm un set de date de la instituțiile publice, fie cele puse la dispoziție de Code4 Romania, transpunerea lor în format reutilizabil e o muncă la care avem nevoie de ajutor.',
      icon: 'assets/imagery/accesibilitate.svg'
    });

    this.day1Schedule = {
      title: 'Sâmbătă',
      subtitle: '22 Iunie',
      location: 'DevPlant Cowork',
      entries: [
        {
          time: '10:00',
          labels: ['Înregistrare', 'Deschidere']
        },
        {
          time: '11:00',
          labels: ['Prezentare Data Portal', 'Datahack']
        },
        {
          time: '13:00',
          labels: ['Prânz', 'Datahack']
        },
        {
          time: '19:00',
          labels: ['Cină', 'Datahack']
        }
      ]
    };

    this.day2Schedule = {
      title: 'Duminică',
      subtitle: '23 Iunie',
      location: 'DevPlant Cowork',
      entries: [
        {
          time: '10:00',
          labels: ['Mic dejun', 'Datahack']
        },
        {
          time: '13:00',
          labels: ['Prânz', 'Datahack']
        },
        {
          time: '15:00',
          labels: ['Prezentări finale', 'Datahack']
        },
      ]
    };

    this.logosSponsori.push({
      targetUrl: 'https://www.movidius.com/',
      imageUrl: 'assets/logos/intel.png',
      order: 1,
      name: 'Intel'
    });

    this.logosSponsori.push({
      targetUrl: 'https://www.here.com/',
      imageUrl: 'assets/logos/here.png',
      order: 2,
      name: 'Here'
    });

    this.logosParteneri.push({
      targetUrl: 'http://code4.ro',
      imageUrl: 'assets/logos/Code4Romania.png',
      order: 1,
      name: 'Code4 Romania'
    });
    this.logosParteneri.push({
      targetUrl: 'https://devplant.ro',
      imageUrl: 'assets/logos/devplant.svg',
      order: 2,
      name: 'DevPlant'
    });
    this.logosParteneri.push({
      targetUrl: 'https://heritageoftimisoara.ro/',
      imageUrl: 'assets/logos/hot.png',
      order: 3,
      name: 'Heritage of Timisoara'
    });
    this.logosParteneri.push({
      targetUrl: 'https://www.facebook.com/mysmartcity.ro/',
      imageUrl: 'assets/logos/smartcity.png',
      order: 4,
      name: 'Smart City'
    });
  }

  ngOnInit() {
  }

}
