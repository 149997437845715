import {Component, Input, OnInit} from '@angular/core';
import {LogoModel} from '../models/logo.model';

@Component({
  selector: 'civ-logo-card',
  templateUrl: './logo-card.component.html',
  styleUrls: ['./logo-card.component.scss']
})
export class LogoCardComponent implements OnInit {

  @Input() logo: LogoModel;

  constructor() { }

  ngOnInit() {
  }

  openTargetUrl() {
    if(this.logo && this.logo.targetUrl) {
      window.open(this.logo.targetUrl, "_blank")
    }
  }

}
