import {Component, Input, OnInit} from '@angular/core';
import {InfoCardModel} from '../models/info-card.model';

@Component({
  selector: 'civ-info-card',
  templateUrl: './info-card.component.html',
  styleUrls: ['./info-card.component.scss']
})
export class InfoCardComponent implements OnInit {

  @Input() infoCard: InfoCardModel;

  constructor() { }

  ngOnInit() {
  }

}
