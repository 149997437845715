import {AfterViewInit, Component, HostListener, OnInit} from '@angular/core';
import {LogoModel} from './models/logo.model';


declare var $;
declare var ScrollPosStyler;

@Component({
  selector: 'civ-root',
  templateUrl: './civ.component.html',
  styleUrls: ['./civ.component.scss']
})
export class CivComponent implements OnInit, AfterViewInit {

  // logosParteneri: LogoModel[] = [];
  //
  // logosSponsori: LogoModel[] = [];


  ngOnInit(): void {

    // const brd: LogoModel = {
    //   targetUrl: 'https://www.brd.ro/',
    //   imageUrl: 'assets/logos/BRD.png',
    //   order: 1,
    //   name: 'BRD'
    // };
    //
    // const intel: LogoModel = {
    //   targetUrl: 'https://www.movidius.com/',
    //   imageUrl: 'assets/logos/intel.png',
    //   order: 5,
    //   name: 'Intel'
    // };
    //
    // const civicultura: LogoModel = {
    //   targetUrl: 'http://civicultura.ro',
    //   imageUrl: 'assets/logos/civicultura.png',
    //   order: 1,
    //   name: 'CiviCultura'
    // };
    //
    // const code4: LogoModel = {
    //   targetUrl: 'http://code4.ro',
    //   imageUrl: 'assets/logos/Code4Romania.png',
    //   order: 2,
    //   name: 'Code4 Romania'
    // };
    //
    // const devPlant: LogoModel = {
    //   targetUrl: 'https://devplant.ro',
    //   imageUrl: 'assets/logos/devplant.svg',
    //   order: 5,
    //   name: 'DevPlant'
    // };
    //
    // const hot: LogoModel = {
    //   targetUrl: 'https://heritageoftimisoara.ro/',
    //   imageUrl: 'assets/logos/hot.png',
    //   order: 7,
    //   name: 'Heritage of Timisoara'
    // }
    //
    // const smartCity: LogoModel = {
    //   targetUrl: 'https://www.facebook.com/mysmartcity.ro/',
    //   imageUrl: 'assets/logos/smartcity.png',
    //   order: 10,
    //   name: 'Smart City'
    // };
    //
    // const smartPol: LogoModel = {
    //   targetUrl: 'https://www.smartpol.org/',
    //   imageUrl: 'assets/logos/smartpol.png',
    //   order: 15,
    //   name: 'SMART POL'
    // };
    //
    // const upt: LogoModel = {
    //   targetUrl: 'https://www.upt.ro/',
    //   imageUrl: 'assets/logos/upt.jpg',
    //   order: 20,
    //   name: 'UPT'
    // };
    //
    // const uvt: LogoModel = {
    //   targetUrl: 'https://www.uvt.ro/',
    //   imageUrl: 'assets/logos/uvt.png',
    //   order: 25,
    //   name: 'UPT'
    // };
    //
    // this.logosParteneri.push(civicultura, code4, devPlant, hot, smartCity, smartPol, upt, uvt);
    // this.logosSponsori.push(brd, intel);

  }

  ngAfterViewInit(): void {
    // Highlight the top nav as scrolling occurs
    $('body').scrollspy({
      target: '.navbar-fixed-top',
      offset: 51
    });

    // Closes the Responsive Menu on Menu Item Click
    $('.navbar-collapse ul li a').click( () => {
      $('.navbar-toggler:visible').click();
    });


  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    const number = document.documentElement.scrollTop;
    if (number > 51) {
      $('#mainNav').addClass('fixed-top');
    }else{
      $('#mainNav').removeClass('fixed-top');
    }
  }


}
